/* purgecss start ignore */
.submenu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #cccfd5;
  box-shadow: 0 2px 20px 0 rgba(153, 153, 153, 0.35);
  display: none;
  font-size: 16px;
  line-height: 1.5;
  width: 320px;
  z-index: 30;
}

.submenu:before {
  position: absolute;
  right: 12px;
  bottom: 100%;
  height: 0;
  width: 0;
  border-bottom: 12px solid #fff;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  content: "";
}

.submenu__item {
  line-height: 1;
}

.submenu__item:not(:last-of-type) {
  border-bottom: 1px solid #f2f3f5;
}

.submenu__item:last-of-type {
  border-bottom-color: transparent;
}

.submenu__item-link {
  transition-property: all;
  transition-duration: 250ms;
  transition-timing-function: ease-out;
  color: #565a5c;
  display: block;
  padding: 12px 18px;
  width: 100%;
  text-align: left;
  background-color: white;
  cursor: pointer;
}

.submenu__item-link:hover {
  transition-property: all;
  transition-duration: 250ms;
  transition-timing-function: ease-in;
  background-color: #f4f7f8;
}

.submenu__item-text {
  color: #565a5c;
  display: block;
  padding: 12px 18px;
}

.submenu__item-text:hover a {
  text-decoration: underline;
}

/* mobile global nav */

.hamburger-mobile-menu-container {
  display: block;
}

.hamburger-mobile-menu-container.hamburger-mobile-menu-container-expanded {
  @apply md:hidden;

  height: fit-content;
}

.hamburger-mobile-menu-items {
  @apply list-none p-0 border-t-2;
}

.hamburger-mobile-menu-item {
  @apply w-full p-4 pr-3 border-b flex justify-between items-center text-lg text-ez-nav-gray;
}

.hamburger-mobile-menu-item .hamburger-mobile-menu-item--caret-down-icon {
  @apply h-4 fill-current text-ez-nav-gray;
}

.hamburger-mobile-menu-item .hamburger-mobile-menu-item--avatar-icon {
  @apply h-4 fill-current text-ez-nav-gray;
}

.mobile-submenu {
  @apply hidden border-ez-gray border-t;
}

.mobile-submenu.submenu-expanded {
  @apply block;
}

.mobile-submenu .submenu-item {
  @apply p-4 text-lg text-ez-nav-gray bg-ez-light-gray border-b border-ez-gray;
}

.submenu-item .submenu-item-link {
  @apply block w-full;
}

/* purgecss end ignore */
