.footer-container {
  @apply w-full px-5 py-12;

  background-color: #373d43; /* bg-ez-dark-gray */
  color: white;
}

.footer-container > div {
  @apply relative m-auto max-w-screen-lg min-h-600;
}

.footer-links-wrapper {
  @apply flex flex-col items-start;
}

.footer-company {
  @apply flex flex-col w-full;
}

.footer-contact {
  @apply flex flex-col justify-between mb-5 text-sm text-ez-gray;
}

.footer-logo-inverse {
  width: 129px;
  height: 31px;
}

.footer-phone,
.footer-mobile,
.footer-envelope {
  width: 15px;
  height: 15px;
}

.footer-contact-24-7 {
  @apply inline-block my-5 text-sm;
}

.footer-contact-method {
  @apply flex h-8 mb-2 leading-8;
}

.footer-contact-icon {
  @apply flex items-center justify-center w-8 h-8 mr-3 rounded-full bg-ez-dark-blue;

  color: white;
}

.footer-contact-link {
  @apply text-ez-blue;
}

.footer-contact-link:hover {
  @apply text-ez-brand-orange;
}

.footer-accordion-target,
.footer-accordion-target-flex,
.footer-accordion-trigger {
  @apply hidden;
}

.footer-accordion-plus-minus:after {
  content: '+';
  @apply absolute right-0 w-8 h-8 text-center rounded-full leading-8;
}

.footer-accordion-trigger:checked ~ .footer-accordion-plus-minus:after {
  content: '—';
}

.footer-accordion {
  @apply p-4 mb-5 border border-white border-solid border-opacity-75 rounded-xl;
}

.footer-accordion-title {
  @apply block font-bold cursor-pointer leading-8 text-ez-light-gray;
}

.footer-accordion-title:hover {
  @apply opacity-75;
}

.footer-accordion-ul {
  @apply mt-4;
}

.footer-accordion-ul-2-col {
  @apply flex-wrap;
}

.footer-accordion-ul-2-col li {
  @apply w-1/2;
}

.footer-accordion-li {
  @apply block mb-3;
}

.footer-accordion-link:hover {
  @apply text-ez-blue;
}

.footer-accordion-plus-minus {
  @apply relative;
}

.footer-accordion-target-flex {
  @apply mt-3;
}

.footer-accordion-trigger:checked ~ .footer-accordion-target {
  @apply block;
}

.footer-accordion-trigger:checked ~ .footer-accordion-target-flex {
  @apply flex;
}

.footer-info {
  @apply mt-5;
}

.footer-info-social {
  @apply hidden;
}

.footer-info-social a:hover {
  @apply text-ez-blue;
}

.footer-info-social span {
  @apply hidden;
}

.footer-info-social i {
  @apply mr-4 text-3xl;
}

.footer-info-policies {
  @apply text-sm leading-8;
}

.footer-info-policies div {
  @apply flex flex-col;
}

.footer-info-policies a {
  @apply py-2 mr-4;
}

.footer-info-policies button {
  @apply py-2 mr-4 text-left;
}

.footer-info-policies span {
  @apply block py-2 opacity-75;
}

.footer-info-policies a:hover {
  @apply text-ez-blue;
}

.footer-info-policies button:hover {
  @apply text-ez-blue;
}

.footer-info-policies a:last-child {
  @apply m-0;
}

.footer-menus {
  @apply flex flex-col w-full;
}

.footer-lps {
  @apply w-full;
}

@screen md {
  .footer-links-wrapper {
    @apply flex-row flex-wrap;
  }

  .footer-company {
    @apply flex flex-row w-full mb-16;
  }

  .footer-contact {
    @apply w-1/2;
  }

  .footer-menus {
    @apply flex-row w-1/2;
  }

  .footer-menus .footer-accordion {
    @apply w-1/2 p-0 m-0 border-none rounded-none;
  }

  .footer-menus .footer-accordion-ul {
    @apply block !important;
  }

  .footer-menus .footer-accordion-plus-minus:after {
    @apply hidden;
  }

  .footer-lps {
    @apply w-1/2 ml-1/2;
  }

  .footer-info {
    @apply absolute mt-0;
    top: 290px;
  }

  .footer-info-social {
    @apply block;
  }
}

@screen lg {
  .footer-links-wrapper {
    @apply flex-row;
  }

  .footer-company {
    @apply w-1/2;
  }

  .footer-lps {
    @apply w-1/2 m-0;
  }
}
