.outro-section {
  background-color: #063938; /* bg-ez-kale-green */
  color: white;
}

.outro-search {
  @apply pt-6 text-center md:pb-12 md:pt-10 md:flex;
}

.outro-search .search-form-header {
  @apply mb-6 text-2xl;
}

.outro-search-form-container {
  @apply px-6 mx-auto md:w-1/2;
  max-width: 36rem;
}

.outro-search::after {
  @apply inline-block w-full bg-center md:bg-left;
  background-image: url('metros_organic/images/icons-footer-background2.svg');
}

.outro-search::before {
  @apply hidden md:bg-right;
  background-image: url('metros_organic/images/icons-footer-background1.svg');
}

.outro-search::after, .outro-search::before {
  @apply h-20 mt-4 bg-repeat-x opacity-40 md:flex md:flex-grow md:w-auto md:mt-8;
  content: "";
}

.outro-value-props {
  @apply justify-around p-8 pt-4 mx-auto text-left md:pb-12 md:pt-0;
  max-width: 73.5rem;
}

.outro-value-prop {
  @apply mb-14;
}

.outro-value-prop h1 {
  @apply mb-6 text-xl;
}

.outro-value-prop h2 {
  @apply mb-5 text-xl;
}

.outro-value-prop ol {
  @apply py-2 pl-8 list-decimal;
}

.outro-value-prop ul {
  @apply pl-10 my-8 list-disc;
}
